import React from "react"
import LayoutNew from "../../components/LayoutNew"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

export default function TermsOfUse() {
  return (
    <LayoutNew>
      <Helmet>
        {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
        <title>Love our internet or it's free! 💜🐄</title>
      </Helmet>
      <section className="px-4 py-8 mx-auto font-light prose prose-lg">
       
        
        <StaticImage
          layout="constrained"
          src="images/love-our-internet-or-its-free.png"
          alt="Purple Cow Internet - love our internet or its free"
          /> 
            <br></br>
            <br></br>
            <h1>Love Our Internet or It's Free!</h1>
              <p>We’ve got some big news at Purple Cow Internet, and we’re thrilled to share it with you! We are confident that our internet service is so awesome that we’re willing to make a bold promise: <strong>"Love our Internet or it's free."</strong></p>
              
              <h2>Why are we doing this? There are several reasons:</h2>
              <ul>
                  <li><strong>Confidence in Our Service:</strong> We know that once you experience the speed, reliability, and exceptional customer service of Purple Cow Internet, you’ll love it. Our internet, TV, and phone services receive amazing reviews online and I know you will love it.</li>
                  <li><strong>The Right Thing to Do:</strong> We believe that providing excellent service should be the standard, not the exception. This promise is our way of holding ourselves accountable to that standard. We want you to feel confident in making the switch to Purple Cow Internet, knowing that your satisfaction is our top priority.</li>
                  <li><strong>Making the Switch Even Easier:</strong> Switching internet providers is pretty easy, but we want to make it even easier. With our promise, you can try our service with peace of mind, knowing that if you’re not completely satisfied within the first 30 days and want to leave the herd, we’ll refund your entire cost.</li>
              </ul>
              
              <p>At Purple Cow, we’re known for popularizing the $60 internet, making high-quality service affordable for everyone. Now, with our new promise, we aim to set a new standard in customer satisfaction. Just as we made affordable internet a reality, we’re committed to making this bold promise a hallmark of our dedication to you.</p>
              
              <p>We are passionate about what we do, and this promise reflects that passion. It’s our way of showing you that we’re not just another internet provider – we’re a company that truly cares about its herd.</p>
              
              <p>If you haven’t given Purple Cow Internet a try, now is the perfect time. Experience the difference for yourself. Join the herd today and discover why so many people are making the switch.</p>
              
              <p>If you are already in the herd thank you for being a part of our journey. Your trust and support mean the world to us. We look forward to continuing to serve you with the best internet service available.</p>
            
            Bradley Farquhar<br></br>
            CEO<br></br>
            <br></br><br></br>

      </section>
    </LayoutNew>
  )
}
